<template>
  <div class="FavoriteButton relative">
    <button
      class="h-32 w-32 text-center"
      :title="anySelected ? $t('favorite.button.remove'):$t('favorite.button.add')"
      :disabled="loadingToFalse || loadingToTrue"
      @click.stop="onSelect"
    >
      <img
        :src="(anySelected || loadingToTrue) && !loadingToFalse ? '/icons/favorite-filled.svg':'/icons/favorite.svg'"
        class="FavoriteButtonIcon h-20 w-20 inline-block"
        :class="{
          'favoriteAnimateIn': (anySelected || loadingToTrue) && !loadingToFalse
        }"
        alt=""
      >
    </button>

    <transition name="fade">
      <FavoriteDropdown
        v-if="showDropdown"
        :product="product"
        :design="design"
        @close="showDropdown = false"
      />
    </transition>
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '~/store/user';

import { ref } from 'vue';
import { ProductModel } from '~/models/product';
import FavoriteDropdown from '~/components/product/FavoriteDropdown.vue';

const props = defineProps<{
  product: ProductModel | string; // if possible, use product, otherwise partno (from cart)
  design: 'standard' | 'featured' | 'small'
}>();

const userStore = useUserStore();
const loadingToTrue = ref(false);
const loadingToFalse = ref(false);
const showDropdown = ref(false);

/**
 * See if any of the variants have a selected
 */
const anySelected = computed(() => {
  const userFav = userStore.userProfile.favoriteProducts.map((m) => m.id);
  if (typeof props.product === 'string') {
    return userFav.includes(props.product);
  } else {
    return userFav.some((s) => props.product.partNumbers?.includes(s));
  }
});

const onSelect = async() => {
  const partNo = typeof props.product === 'string' ? props.product:props.product.partNo;

  if (typeof props.product !== 'string' && props.product.hasVariants) {
    showDropdown.value = !showDropdown.value;
  } else {
    if (anySelected.value) {
      // remove
      loadingToFalse.value = true;
      await userStore.updateFavoriteProductQuantity(partNo, 0);
      loadingToFalse.value = false;
    } else {
      loadingToTrue.value = true;
      await userStore.saveFavoriteProduct(partNo);
      loadingToTrue.value = false;
    }
  }
};

</script>

<style lang="postcss">
</style>
